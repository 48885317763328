import React, { FunctionComponent } from 'react';
import parse, { domToReact } from 'html-react-parser';
import Gist from 'react-gist';
import styles from './blog-article-content.module.scss';

interface IProps {
  content: string;
  className?: string;
}

const P = ({ children }: { children: string }): JSX.Element => (
  <p className={styles.paragraph}> {children} </p>
);

const H1 = ({ children }: { children: string }): JSX.Element => (
  <h1 className={styles.headerExtraLarge}> {children} </h1>
);

const H2 = ({ children }: { children: string }): JSX.Element => (
  <h2 className={styles.headerLarge}> {children} </h2>
);

const H3 = ({ children }: { children: string }): JSX.Element => (
  <h3 className={styles.headerRegular}> {children} </h3>
);

const EM = ({ children }: { children: string }): JSX.Element => (
  <i> {children} </i>
);

const STRONG = ({ children }: { children: string }): JSX.Element => (
  <strong> {children} </strong>
);

const UL = ({ children }: { children: string }): JSX.Element => (
  <div className={styles.listWrapper}>
    <ul className={styles.unorderedList}>{children}</ul>
  </div>
);

const LI = ({ children }: { children: string }): JSX.Element => (
  <li className={styles.listItem}>
    <span>{children}</span>
  </li>
);

const A = (props): JSX.Element => {
  return (
    <a
      className={styles.link}
      target={'_blank'}
      rel="noopener noreferrer"
      href={props.attribs.href ? props.attribs.href : '#'}
    >
      {props.children[1] || props.children}
    </a>
  );
};

const SCRIPT = (props): JSX.Element | null => {
  try {
    const getSrc = props.attribs.src;
    const srcParts = getSrc.split('/');
    const idParts = srcParts[srcParts.length - 1].split('.');
    const id = idParts[0];
    return id && id.length ? (
      <Gist id={id} />
    ) : (
      <a href={props.attribs.src}>Script</a>
    );
  } catch (err) {
    return <a href={props.attribs.src}>Script</a>;
  }
};

const componentsMapping = {
  a: A,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  li: LI,
  ul: UL,
  em: EM,
  strong: STRONG,
  script: SCRIPT
};

const options = {
  replace: function(domNode): JSX.Element | undefined {
    if (domNode.name in componentsMapping) {
      const T = componentsMapping[domNode.name];
      if (!T) {
        return;
      }

      return (
        <T attribs={domNode.attribs}>
          {' '}
          {domToReact(domNode.children, options)}{' '}
        </T>
      );
    }
    return;
  }
};

export const BlogArticleContent: FunctionComponent<IProps> = props => {
  return (
    <div className={styles.contentContainer}>
      <div className={props.className}>{parse(props.content, options)}</div>
    </div>
  );
};
