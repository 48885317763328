import React, { FunctionComponent } from 'react';
import styles from './article-hero.module.scss';
import { ICategoryModel } from '../../models/category-model';
import { IEmployeeBoxProps } from '../employee-box/employee-box';
import { ArticleAuthorSmallBox } from '../article-author-small-box/article-author-small-box';
import { InternalLink } from '../internal-link/internal-link';
import { authorUrl } from '../../utils/utils';

export interface IArticleHeroProps {
  backgroundImage: {
    url: string;
    description: string;
  };
  headline: string;
  tags?: ICategoryModel[];
  bottomInfo: string;
  subTitle?: string;
  title: string;
  publicationDate?: string;
  author?: IEmployeeBoxProps;
  headlineLight?: string;
  linkBackUrl?: string;
}

export const ArticleHero: FunctionComponent<IArticleHeroProps> = props => {
  const {
    backgroundImage,
    headline,
    tags,
    bottomInfo,
    subTitle,
    title,
    publicationDate,
    author,
    headlineLight,
    linkBackUrl
  } = props;

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${backgroundImage.url})` }}
    >
      <div className={styles.content}>
        <div
          className={`${styles.header} ${
            !tags && !author ? styles.marginBottom : null
          }`}
        >
          <InternalLink
            className={styles.title}
            url={linkBackUrl ? linkBackUrl : '#'}
          >
            {title}
          </InternalLink>
          {(publicationDate || headlineLight) && (
            <div className={styles.publicationDate}>
              {publicationDate || headlineLight}
            </div>
          )}
          <h1 className={styles.headline}>{headline}</h1>
          {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
        </div>
        {tags && (
          <div className={styles.tags}>
            {tags.map((tag: ICategoryModel, i: number) => (
              <span className={styles.tag} key={i}>
                {tag.categoryName}
              </span>
            ))}
          </div>
        )}
        {author && (
          <InternalLink url={authorUrl(author.slug)}>
            <div className={styles.author}>
              <ArticleAuthorSmallBox author={author} displayPosition={true} />
            </div>
          </InternalLink>
        )}
        {bottomInfo && <div className={styles.bottomInfo}>{bottomInfo}</div>}
      </div>
    </div>
  );
};
