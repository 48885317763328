import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  useContext,
  useEffect,
  FunctionComponent,
  useMemo
} from 'react';
import { PageProps } from 'gatsby';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import '../styles/global.css';
import '../styles/reset.css';
import { pageDataResolver } from '../utils/pageDataResolver';
import { SeoComponent } from '../components/seo-component/seo-component';
import { Navbar } from '../components/navbar/navbar';
import { Footer } from '../components/footer/footer';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { BlogArticleModel } from '../models/blog-article.model';
import { IBlogArticleItem } from '../components/blog/blog-article-list-item/blog-article-list-item';
import { ArticleHero } from '../components/article-hero/article-hero';
import format from 'date-fns/format';
import { getLocaleByCode } from '../utils/locale';
import { popularArticlesDataResolver } from '../utils/popularArticlesDataResolver';
import { BlogArticleContent } from '../components/blog/blog-article/blog-article-content/blog-article-content';
import { Container } from '../components/container/container.component';
import { ShareButtons } from '../components/share-buttons/share-buttons';
import { BlogPopularArticles } from '../components/blog/blog-article/blog-popular-articles/blog-popular-articles';
import { BlogAuthorSection } from '../components/blog/blog-author-section/blog-author-section';
import styles from '../components/blog/blog-article/blog-article.module.scss';
import { seoDataResolver } from '../components/seo-component/seoDataResolver';
import { BLOG_URL } from '../const/url';
import { withCookieBar } from '../hoc/withCookieBar';
import RichText from '../components/rich-text/rich-text';

interface IData {
  kontentItemPage: IPage;
  kontentItemBlogArticle: IBlogArticle;
  popularFromIds: {
    nodes: IBlogPopularArticle[];
  };
  popularFromCategory: {
    nodes: IBlogPopularArticle[];
  };
  cookieSection: ICookieData;
}

type BlogArticlePageProps = PageProps<IData, IPageContext>;

const BlogArticlePage: FunctionComponent<BlogArticlePageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);

    debouncedResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    state.showActiveDevice,
    state.showPageState.currentUri,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    props.data.cookieSection.elements.text.value,
    props.data.cookieSection.elements.button_cta.value
  ]);

  const blogArticleData: IBlogArticleItem = useMemo(
    () => BlogArticleModel.create(props.data.kontentItemBlogArticle),
    [props.data.kontentItemBlogArticle]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);
  const seoData = useMemo(
    () =>
      props.data.kontentItemBlogArticle.elements
        ? seoDataResolver(props.data.kontentItemBlogArticle.elements)
        : null,
    [props.data.kontentItemBlogArticle.elements]
  );

  const locale = useMemo(
    () => getLocaleByCode(props.pageContext.pageLanguage),
    [props.pageContext.pageLanguage]
  );
  const dateToDisplay = useMemo(
    () =>
      format(new Date(blogArticleData.publicationDate), 'dd MMMM yyyy', {
        locale
      }),
    [blogArticleData.publicationDate, locale]
  );
  const popularArticlesData = useMemo(
    () =>
      popularArticlesDataResolver(
        props.data.popularFromIds,
        props.data.popularFromCategory
      ),
    [props.data.popularFromIds, props.data.popularFromCategory]
  );

  return (
    <>
      <SeoComponent {...seoData} />
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <ArticleHero
        linkBackUrl={BLOG_URL}
        backgroundImage={blogArticleData.image}
        headline={blogArticleData.title}
        bottomInfo={blogArticleData.categoryName}
        publicationDate={dateToDisplay}
        author={blogArticleData.author}
        title="BLOG"
      />
      <Container>
        <div className={styles.blogArticleWrapper}>
          <ShareButtons className={styles.shareButtons} />
          <div className={styles.kenticoContent}>
            {blogArticleData.contentHtml && (
              <BlogArticleContent
                className={styles.blogArticleContent}
                content={blogArticleData.contentHtml}
              />
            )}
            {blogArticleData.content && (
              <RichText {...blogArticleData.content} />
            )}
          </div>
          <BlogPopularArticles
            className={styles.blogPopularArticles}
            articles={popularArticlesData}
          />
        </div>
      </Container>
      {blogArticleData.author.photoUrl && (
        <BlogAuthorSection author={blogArticleData.author} />
      )}
      {pageData.footerData.linkColumns.length && (
        <Footer {...pageData.footerData} />
      )}
    </>
  );
};

export default withAppStore(
  withCookieBar(BlogArticlePage as FunctionComponent<{}>)
);

export const query = graphql`
  query(
    $id: String
    $pageLanguage: String
    $category: String
    $popularArticles: [String]
  ) {
    kontentItemBlogArticle(
      system: { id: { eq: $id }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentBlogArticle
      ...FragmentBlogSEO
    }
    popularFromCategory: allKontentItemBlogArticle(
      filter: {
        system: { language: { eq: $pageLanguage }, id: { ne: $id } }
        elements: {
          blog_category: {
            value: { elemMatch: { system: { codename: { eq: $category } } } }
          }
        }
      }
      sort: { fields: elements___publication_date___value, order: DESC }
      limit: 5
    ) {
      nodes {
        ...FragmentBlogPopularArticle
      }
    }
    popularFromIds: allKontentItemBlogArticle(
      filter: {
        id: { in: $popularArticles }
        system: { language: { eq: $pageLanguage } }
      }
      sort: { fields: elements___publication_date___value, order: DESC }
      limit: 3
    ) {
      nodes {
        ...FragmentBlogPopularArticle
      }
    }
    kontentItemPage(
      system: { language: { eq: $pageLanguage }, codename: { eq: "homepage" } }
    ) {
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
